import React, {Component} from 'react';
import {NavBar} from '../NavBar';
import {Footer} from '../Footer';
import {AboutPane} from './AboutPane';
import {AboutProfiles} from './AboutProfiles-v1';
import {AboutChats} from './AboutChats';
import { JoinBar } from '../JoinBar';
import "../about/about.css";

export class AboutPage extends Component {
    render(){
        return(
            <div>
                <NavBar
                    isAuthenticated={this.props.isAuthenticated}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <AboutPane />
                <AboutProfiles />
                <AboutChats />
                <JoinBar />
                <Footer />
            </div>
        );
    }
}
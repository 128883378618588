import React, {Component} from 'react';

const imgDir = "./images/icons/"

const logoDir = [
    imgDir + "mail.png",
    imgDir + "LinkedIn.png",
    imgDir + "discord.png",
    imgDir + "instagram.png",
    imgDir + "facebook.png"
]

const link = [
    "mailto:utefa@g.skule.ca",
    "https://www.linkedin.com/company/utefa/",
    "https://discord.gg/XAgpPpC9qd",
    "https://www.instagram.com/utefa_uoft/",
    "https://www.facebook.com/utefa"
]

const handle = [
    "utefa@g.skule.ca",
    "linkedin.com/company/utefa",
    "UTEFA - server invite",
    "@utefa_uoft",
    "facebook.com/utefa",
]

export class Contact extends Component{
    render(){
        return(
            <div className="contact">
                <hr className = "line"></hr>
                <div className = "contactContent">
                    <div className = "wow fadeIn contactText">
                        <div className = "textTitle">Get in Touch</div>
                        <div className = "textSubtitle">Follow our socials to stay in the loop!</div>
                        <div className = "contactInfo">
                            {handle.map((handle, i) =>
                                <a href = {link[i]} target = "_blank" rel="noopener noreferrer">
                                    <img src = {logoDir[i]} alt = "social media"></img>
                                    <div className = "normText">{handle}</div>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className = "wow fadeInRight analyst">
                        <div className = "textHeading">Become a UTEFA Analyst</div>
                        <div className = "analystForm">
                            <img src = "./images/icons/form.png" alt = "form"></img>
                            <div className = "analystText">
                                <div className = "normText">
                                    Fill out this form to subscribe to our general mailing list, get 
                                    notified on about our meetings and events, and join our programs.
                                </div>
                                <a href = "https://forms.gle/8oitucii6tWPkTUv6" target = "_blank" rel = "noopener noreferrer" className = "wow pulse contentButton pinkButton" data-wow-delay = "1s">Register Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className = "line"></hr>
            </div>
        )
    }
}
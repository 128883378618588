import React, {Component} from 'react';
import {AlumniLogo} from './AlumniLogo';

const imgDir = './images/companies/';

const logos = [
  imgDir + 'AQR.jpg',
  imgDir + 'BOA.png',
  imgDir + 'Battery.png',
  imgDir + 'BH.png',
  imgDir + 'BMO.png',
  imgDir + 'CIBC_AM.png',
  imgDir + 'CIBC_CM.png',
  imgDir + 'CPP.png',
  imgDir + 'Deloitte.png',
  imgDir + 'Ebay.png',
  imgDir + 'EY.png',
  imgDir + 'FI.png',
  imgDir + 'FN.png',
  imgDir + 'GS.png',
  imgDir + 'Huawei.png',
  imgDir + 'IESO.jpg',
  imgDir + 'Ion.jpg',
  imgDir + 'Meta.png',
  imgDir + 'MS.png',
  imgDir + 'OTPP.png',
  imgDir + 'Palantir.png',
  imgDir + 'RBC.png',
  imgDir + 'Scotia.png',
  imgDir + 'TD.png'
];

export class OurAlumni extends Component {
  render() {
    const logoDivs = logos.map((logo, index) => {
      return <AlumniLogo key={index} src={logo}/>
    });

    return(
      <div className = "alumLogos">
        <hr className = "line"></hr>
        <div className="wow fadeIn alumni-cont">
          {logoDivs}
        </div>
        <hr className = "line"></hr>
      </div>
    );
  }
}

import React, {Component} from 'react';

const links = [
    './images/companies/BOA-Icon.png',
    "./images/companies/EY-Icon.png",
    "./images/companies/FI-Icon.png",
    "./images/companies/GS-Icon.png",
    "./images/companies/AQR-Icon.png",
    "./images/companies/OTPP-Icon.png",
    "./images/companies/RBC-Icon.png",
    "./images/companies/TD-Icon.jpg",
];

const colors = [
    "borderPink", "borderYellow", "borderPink", "borderYellow",
     "borderYellow", "borderPink", "borderYellow", "borderPink"
];

export class HomeAlumni extends Component {
  render() {
    return(
        <div className = "homeAlumni">
            <div className = "textTitle">
                Alumni Placements
                <div className = "textSubtitle">
                        UTEFA has built a vast alumni network 
                        across both tech and finance industries including ...
                </div>
            </div>
            <div className = "alumIconMap">
                {links.map((links, i) =>
                    <div className = "alumniIcon">
                        <img src = {links} className = {"wow rotateIn " + colors[i]} alt = "alum logo"></img>  
                    </div>
                )}
            </div>
            <a href = "/alumni" className = "wow pulse contentButton pinkButton">All Alumni Placements</a>
        </div>
    );
  }
}

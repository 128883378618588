import React, {Component} from 'react';

export class AlumniPane extends Component {
  render() {
    return(
        <div className="pane shortPane">
            <div className="wow fadeInLeft title">Our Alumni</div>
            <div className="wow fadeInRight slogan">Find UTEFA alumni across North America</div>
        </div>
    );
  }
}

import React, {Component} from 'react';
import {NavBar} from '../NavBar';
import {Footer} from '../Footer';
import {OurAlumni} from './OurAlumni';
import {AlumniPane} from './AlumniPane';
import { JoinBar } from '../JoinBar';
import './alumni.css';

export class AlumniPage extends Component {
    render(){
        return(
            <div>
                <NavBar
                    isAuthenticated={this.props.isAuthenticated}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <AlumniPane />
                <OurAlumni />
                <JoinBar />
                <Footer />
            </div>
        );
    }
}
import React, {Component} from 'react';
import {JoinButton} from '../JoinButton';

export class HomePane extends Component {
  render() {
    return(
      <div className = "pane">
        <div className = "logoName">
          <img className = "wow fadeInLeft" src = "./images/logos/LogoWhite.png" alt = "logo" width = "200" height = "100%"></img>
          <div className = "wow fadeInRight clubName">
            <div className = "subtitle">University of Toronto</div>
            <div className = "title">Engineering Finance Association</div>
          </div>
        </div>
        <div className = "animated fadeInUp slogan">Envision, Believe, Invest</div>
        <JoinButton />
      </div>
    );
  }
}

import React, {Component} from 'react';

export class HomeEvents extends Component {
  render() {
    return(
        <div className = "homeEvents">
            <hr className = "line"></hr>
            <div className = "textTitle">Club Events
                <div className = "textSubtitle">
                        We’ve got exciting events planned for the upcoming year,
                        follow us to get the most recent updates!
                </div>
            </div>
            <div className = "eventsContent">
                <div className = "eventsItem">
                    <img src = "./images/Event.jpg" className = "borderNavy" alt = "stock pitch"></img>
                    <div className = "eventsText">
                        <div className = "eventsTitle textNavy">Stock Pitch Competitions</div>
                        <div className = "wow fadeInRight normText"> 
                            UTEFA will be hosting a two-stage stock pitch competition, inviting 
                            alumni and seasoned industry professionals to judge. 
                            There will be multiple workshops and a handful of resources to help participants prepare 
                            so previous experience is not required.
                        </div>
                    </div>
                </div>
                <div className = "eventsItem">
                    <div className = "eventsText">
                        <div className = "eventsTitle textPink">Alumni Panels</div>
                        <div className = "wow fadeInLeft normText">
                            Every year, UTEFA alumni across various sectors come to our meetings to speak about their experiences 
                            in the finance industry and connect with current members. We’ll be inviting 
                            alumni in a range of sectors and from various locations across North America. 
                            Remember to come with questions!
                        </div>
                    </div>
                    <img src = "./images/AlumniPanel.jpg" className = "borderPink" alt = "alumni panel"></img>
                </div>
                <div className = "eventsItem">
                    <img src = "./images/workshops.jpg" className = "borderBlue" alt = "workshops"></img>
                    <div className = "eventsText">
                        <div className = "eventsTitle textBlue">General Workshops</div>
                        <div className = "wow fadeInRight normText">
                            On weeks with no special events, UTEFA hosts general meetings to teach 
                            foundational concepts, practice technical skills, and answer any of your 
                            questions. This is your chance to speak with the exec team, meet new club 
                            members, and talk about your favourite topics!
                        </div>                           
                    </div>
                </div>
            </div>
            <a href = "/schedule" className = "wow pulse contentButton pinkButton">Find Our Schedule</a>
        </div>
    );
  }
}

import React, {Component} from 'react';

export class AboutChats extends Component {
  render() {
    return(
      <div className = "aboutChats">
        <hr className = "line"></hr>
        <div className = "chatsContent">
          <div className = "chatsText">
            <div className = "textHeading">Exec Coffee Chats</div>
            <div className = "textCaption">Want to speak to an exec member?</div>
            <div className = "wow fadeInLeft normText">
              We'd love to help! Reach out through LinkedIn or Discord and we will 
              respond at our earliest convenience. 
            </div>
            <div className = "wow fadeInLeft normText">
              Ask us about anything - career advice, resume polishing, interview 
              practice, or even just a casual chat. While we’re not seasoned 
              professionals, we can share our experiences and connect you with 
              alumni that may provide further guidance.
            </div>
          </div>
          <img src ="./images/icons/coffee.png" alt = "coffee chats"></img>
        </div>
      </div>
    )
  }
}
import React, {Component} from 'react';

export class MaterialsPane extends Component {
  render() {
    return(
      <div className="pane midPane">
        <div className="wow title">Resources</div>
        <div className="wow fadeInRight slogan">Find materials presented events</div>
      </div>
    );
  }
}

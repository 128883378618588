import React, {Component} from 'react';

export class Partners extends Component {
    render() {
      return(
        <div className="partners">
            <hr className = "line"></hr>
            <div className = "collab">
              <div className = "wow fadeInRight pcContent">
                <div className = "normText">
                  UTEFA and Paurana Capital’s research teams have joined forces to give our analysts the latest market insights! Every Monday, we will release a newsletter and announcement through our market updates channels.             
                </div>
                <div className = "normText">
                  Join our mailing list to receive the weekly newsletter, and join our Discord server or come to weekly meetings to participate in discussions, ask questions, or propose a topic for our next newsletter.
                </div>
              </div>
              <div className = "wow fadeInLeft pcTitle">
                <img src = "./images/logos/UTEFAxPC.png" width = "225px" alt = "logo"></img> 
                <div className = "textHeading">Market Mondays</div>
                <div className = "textSubtitle">UTEFA x Paurana Capital</div>
              </div>
            </div>
            <div className = "collab">
              <div className = "wow fadeInRight ctcTitle">
                <img src = "./images/logos/UTEFAxCTC.png" width = "300px" alt = "logo"></img>
                <div className = "textHeading">Mentorship Program</div>
                <div className = "textSubtitle">UTEFA x Coffee to Capital</div>
              </div>
              <div className = "wow fadeInLeft ctcContent">
                <div className = "normText">
                  UTEFA and CTC have come together to create a mentorship program that prepares you for your next coffee chat, networking event, and interview.
                </div>
                <div className = "normText">
                  You'll be matched to an upper year, and it's up to you to proactively stay in contact with them. When they think you're ready, we'll connect you with an alumni as the next step to growing your network. Follow our socials to know when applications open!
                </div>
              </div>
            </div>
            <hr className = "line"></hr> 
        </div>
      );
    }
  }
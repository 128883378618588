import React, {Component} from 'react';

export class KeepUp extends Component {
    render() {
      return(
        <div className="keepUp">
            <div className = "joinText">Want to know when this page updates?</div>
            <a href = "https://forms.gle/8oitucii6tWPkTUv6" target = "_blank" rel = "noopener noreferrer" className = "wow pulse contentButton blueButton">
              Join our mailing list
              </a>
            
        </div>
      );
    }
  }
import React, {Component} from 'react';
import {JoinButton} from './JoinButton';

export class JoinBar extends Component {
  render() {
    return(
        <div className = "joinBar">
            <div className = "joinText">UTEFA sounds like the club for you?</div>
            <JoinButton />
        </div>
    );
  }
}
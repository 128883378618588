import React, {Component} from 'react';

const programName = [
    "Stock pitch team",
    "Market Updates Team",
    "Internship Program",
    "Mentorship Program"
]

const colors = [
    "textBlue borderBlue",
    "textPink borderPink",
    "textYellow borderYellow",
    "textNavy borderNavy"
]

const imgDir = "./images/icons/"

const iconDir = [
    imgDir + "pitch.png",
    imgDir + "market.png",
    imgDir + "intern.png",
    imgDir + "mentor.png"
]

const desc = [
    ["Join a team of analysts to prepare a stock pitch and present at our November meetings, judged by execs. Outstanding teams advance to UTEFA’s Stock Pitch Superday where you’ll receive feedback from alumni and win a prize!",
       "Each novice team is led by an exec and there will be multiple workshops throughout the year so no previous experience is required!"],
    ["Led by our Head Economist and Head Analyst, the Market Updates Teams gather market news across various sectors, develop views on future market behaviour, and create weekly announcements in our Server.",
        "If there’s a sector that you’re particularly interested in, this is your opportunity explore those topics and start interesting discussions with your fellow analysts."],
    ["Each year, UTEFA takes on one first year and one second year student as interns. You will be joining our team, organizing events and educational materials, and helping execs wherever needed.",
        "Interns are guaranteed a spot on next year’s exec team. Recruiting starts in November so keep an eye out for our announcements and come to meetings to connect with execs!"],
    ["Led by our Head Economist and Head Analyst, the Market Updates Teams gather market news across various sectors, develop views on future market behaviour, and create weekly announcements in our Server.",
        "If there’s a sector that you’re particularly interested in, this is your opportunity explore those topics and start interesting discussions with your fellow analysts."]
]

export class Programs extends Component{
    render(){
        return(
            <div className = "programs">
                <div className = "textTitle">Teams and Programs</div>
                <div className = "textSubtitle">Already an analyst and want to be more involved? Take a look at the different programs and teams you can join at UTEFA.</div>
                <div className = "programGrid">
                    {programName.map((program, i) =>
                        <div className = {"programTeam wow fadeInUp " + colors[i]}>
                            {program}
                            <img src = {iconDir[i]} alt = "programs"></img>
                            <div className = "normText">{desc[i][0]}</div>
                            <div className = "normText">{desc[i][1]}</div>
                        </div>
                    )}                    
                </div>
            </div>
        )
    }
}

import React, {Component} from 'react';
import './home-v1.css';
import {NavBar} from '../NavBar'
import {HomePane} from './HomePane';
import {HomeIntro} from './HomeIntro';
import {HomeTeam} from './HomeTeam-v1';
import {HomeEvents} from './HomeEvents';
import {HomeResources} from './HomeResources';
import {HomeAlumni} from './HomeAlumni';
import {Footer} from '../Footer';
import { JoinBar } from '../JoinBar';


export class Homepage extends Component {
  render(){
    return(
      <div>
          <NavBar
            isAuthenticated={this.props.isAuthenticated}
            userHasAuthenticated={this.props.userHasAuthenticated}
          />
          <HomePane />
          <HomeIntro />
          <HomeTeam />
          <HomeEvents />
          <HomeResources />
          <HomeAlumni />
          <JoinBar />
        <Footer />
      </div>
    );
  }
}

import React, {Component} from 'react';

export class HomeIntro extends Component {
  render() {
    return(
        <div className = 'homeIntro'>
            <hr className = "line"></hr>
            <div className = "introImage">
                <img src = "./images/icons/Intro1.png" alt = "intro"></img>
                <div className = "wow fadeInRight introText">
                    <div className = "textHeading">Helping engineers step into finance.</div>
                    <div className = "textCaption">Studying engineering but want to enter finance? UTEFA is here to help!</div>
                    <div className = "normText">UTEFA provides Engineering students interested in a professional career in finance with resources to gain knowledge and experience through workshops, conferences, and opportunities to connect with industry professionals.</div>
                    <div className = "normText">Since our inception in 2009, UTEFA has developed a vast alumni network within corporations across North America and is known to foster passionate individuals strong in both quantitative analysis and qualitative assessment.</div>
                </div>
            </div>
            <div className = "introImage">
                <div className = "wow fadeInLeft introText">
                    <div className = "textHeading">Explore career paths in the industry</div>
                    <div className = "textCaption">Learn about the various sectors of finance and discover your true passion.</div>
                    <div className = "normText">As an engineer, your strong quantitative skills make you a competitive candidate. UTEFA provides the platform for you to explore the various opportunities in the field and expand your horizons, learning key skills to help kick start your transition into finance.</div>
                    <div className = "normText">Connect with our alumni and executive team to learn about their experiences and receive guidance on how to build a stronger resume, improve interview skills, and showcase technical knowledge.</div>
                </div>
                <img src = "./images/icons/Intro2.png" alt = "intro"></img>
            </div>
            <hr className = "line"></hr>
        </div>
    );
  }
}
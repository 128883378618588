import React, {Component} from 'react';

export class AboutPane extends Component {
  render() {
    return(
        <div className="pane">
            <div className="wow fadeInLeft title">About Us</div>
            <div className="wow fadeInRight slogan">Envision, Believe, Invest</div>
            <div className="wow fadeInUp mission">
              <div className="missionTitle">Our Mission</div>
              To create an environment where students can learn about various sectors of 
              the finance industry while gaining experience through direct engagement in 
              investing, market reports, and stock pitches.
            </div>
        </div>
    );
  }
}

import React, {Component} from 'react';
import {NavBar} from '../NavBar';
import {Footer} from '../Footer';
import { JoinBar } from '../JoinBar';
import {SchedulePane} from './SchedulePane';
import Collapse from './Collapsible-v1';
import "./schedule.css";

export class SchedulePage extends Component {
    render(){
        return(
            <div>
                <NavBar 
                    isAuthenticated={this.props.isAuthenticated}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <SchedulePane />
                <Collapse />
                <JoinBar />
                <Footer />
            </div>
        )
    }
}
import React, {Component} from 'react';

export class PartnersPane extends Component{
    render(){
        return(
            <div className="pane midPane">
                <div className="wow fadeInLeft title">Partners</div>
                <div className="wow fadeInRight subSlogan">
                    We have exciting cross-club collaborations planned this year.
                    Find out more!
                </div>
            </div>
        )
    }
}
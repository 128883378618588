import React, {Component} from 'react';
import {NavBar} from '../NavBar';
import {Footer} from '../Footer';
import { JoinBar } from '../JoinBar';
import {MaterialsPane} from './MaterialsPane';
import {Materials} from './Materials';
import {KeepUp} from './KeepUp';
import { MaterialsQA } from './MaterialsQA';
import './materials.css'


export class ResourcesPage extends Component {
    render(){
        return(
            <div>
                <NavBar
                    isAuthenticated={this.props.isAuthenticated}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <MaterialsPane />
                <KeepUp />
                <Materials />
                <MaterialsQA />
                <JoinBar />
                <Footer />
            </div>
        );
    }
}
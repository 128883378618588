import React, {Component} from 'react';
import {JoinButton} from '../JoinButton';

export class SchedulePane extends Component{
    render(){
        return(
            <div className = "pane">
                <div className = "wow fadeInLeft title">Schedule</div>
                <div className = "wow fadeInRight subSlogan">
                    We have exciting events planned this year,
                    follow our socials to stay updated!
                </div>
                <JoinButton />
            </div>
        )
    }
}
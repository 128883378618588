import React, {Component} from "react";
import {JoinPane} from './JoinPane';
import { Programs } from "./Programs";
import {NavBar} from '../NavBar';
import {Footer} from '../Footer';
import {Contact} from './Contact';
import './join.css'

export class JoinPage extends Component{
    render(){
        return(
            <div>
                <NavBar 
                    isAuthenticated={this.props.isAuthenticated}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <JoinPane />
                <Contact />
                <Programs />
                <Footer/>
            </div>           
        )
    }
}
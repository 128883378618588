import React, {Component} from 'react';

const title = [
  "President", "VP Operations", 
  "VP Finance", "Portfolio Manager",
  "Head Economist", "Head Analyst", 
  "VP External","Corporate Relations", 
  "VP Marketing", "Faculty Advisor"
]

const name = [
  "Sarah Chen", "Ella Ceroni",
  "Edward Xu", "Shawn Teh",
  "Harit Paurana", "Alex Dudek",
   "Aditya Mishra","Joyce Li",
  "Rosana Gao", "Yuri Lawryshyn"
]

const program = [
  "Engsci EMSF 2T6", "Engsci EMSF 2T6",
  "Indy 2T5 + PEY", "Indy 2T5 + PEY",
  "ECE 2T7 + PEY", "Chem 2T6 + PEY",
   "Indy 2T7 + PEY","Rotman 2026",
  "Engsci EMSF 2T6 + PEY", "Associate Professor"
]

const imgdir = [
  "./images/pfps/Sarah.jpg", "./images/pfps/Ella.jpg",
  "./images/pfps/Edward.jpg", "./images/pfps/Shawn.png",
  "./images/pfps/Harit.JPEG", "./images/pfps/Alex.jpg",
  "./images/pfps/Aditya.png", "./images/pfps/Joyce.jpg",
  "./images/pfps/Rosana.jpg", "./images/pfps/Lawryshyn.jpg",
]

const linkedin = [
  "https://www.linkedin.com/in/sarah-y-chen/",
  "https://www.linkedin.com/in/ella-ceroni-800992220/",
  "https://www.linkedin.com/in/edward-xu-ab61b81a2",
  "https://www.linkedin.com/in/shawn-teh/",
  "https://www.linkedin.com/in/haritpaurana",
  "https://www.linkedin.com/in/dudekalexander/",
  "https://www.linkedin.com/in/aditya-mishra-a26946257/",
  "https://www.linkedin.com/in/jxyceli88/",
  "https://www.linkedin.com/in/rosana-gao/",
  "https://www.linkedin.com/in/yuri-lawryshyn-ab2a7042/"
]

// const descs = [
//   "I’m Sarah, super excited to lead the UTEFA team! This summer I’m doing fixed income research in Asset Management and will be going into quant finance next year. I’m passionate about bringing more women and engineers into finance, watching dramas, and getting little treats.",
//   "",
//   "Edward interns at a private equity firm, where he supports buyout deals and strategic projects. He previously worked in power & utilities and gained exposure to commodities hedging, and worked on a change management project as a summer researcher at a hospital.",
//   "Shawn is an industrial engineering student interning at an energy company, where he is involved in emissions modeling and renewable energy spot and forwards. Previously, he worked at a tech company that specializes in digital banking solutions.",
//   "Harit is a second-year Computer Engineering student with a profound passion for options trading and derivatives and worked as a machine learning intern at a tech startup this summer. He enjoys playing cricket and golf, and pursuing astrophotography.",
//   "",
//   "Aditya is a second-year Industrial engineering student with a background in M&A consulting and diligence, bringing strong communication, analytical, and presentation skills. Outside of consulting and high finance Aditya likes to play guitar, read, do taekwondo, and work out.",
//   "Hi! I’m Joyce, third year Rotman specializing in Management. I previously interned at Scotiabank in Global Equity Derivatives and am now at EY in Banking Tech Consulting. I’m happy to chat about anything consulting, tech, finance, and food (love me a good café)!",
//   "Hi! I’m Rosana and I’m a third-year EngSci majoring in Math, Stats, & Finance. This summer, I’m working as a data optimization analyst at a start-up. I like graphic design, the xlookup function, and popsicles! :-)",
//   "Professor Yuri Lawryshyn is an associate professor of Chemical Engineering & Applied Chemistry. His research is focused on applying mathematical and numerical modelling in financial analysis and applied computational fluid dynamics (CFD)."
// ]

const color = [
  "borderPink", "borderPink",  "borderBlue", "borderBlue", "borderYellow", 
  "borderYellow", "borderNavy", "borderNavy", "borderPink", "borderPink"
]

export class AboutProfiles extends Component {
  render() {
    return (
      <div className = "team">
        <hr className = "line"></hr>
        <div className = "textTitle">Meet the Team</div>
        <div className = "teamProfiles">
          {title.map((title, i) =>
            <div className = "wow fadeInUp profile">
              <img src = {imgdir[i]} className = {"pfp " + color[i]} alt = "profile"></img>
              <div className = "profileText">
                <div className = "textHeading">{name[i]}
                  <a href = {linkedin[i]} target = "_blank" rel = "noopener noreferrer">
                    <img src = "./images/icons/LinkedIn.png" className = "linkedin" alt = "linkedin"></img>
                  </a>
                </div>
                <div className = "subText">{title}</div>
                <div className = "textCaption">{program[i]}</div>
                {/* <div className = "normText">{descs[i]}</div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
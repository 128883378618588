import React, {Component} from 'react';

export class Footer extends Component {
  render() {
    return(
      <div className = "footer">
        <a href = '/'>
          <img src = "./images/logos/LogoWhiteSide.png" alt = "logo"></img>
        </a>
        <div className = "footerText">
          <div className = "footerLinks">
            <a href = "https://www.linkedin.com/company/utefa/" target = "_blank" rel="noopener noreferrer" className = "textPink">LinkedIn</a>
              | 
            <a href = "https://www.instagram.com/utefa_uoft/" target = "_blank" rel="noopener noreferrer" className = "textYellow">Instagram</a>
              | 
            <a href = "https://discord.gg/XAgpPpC9qd" target = "_blank" rel="noopener noreferrer" className = "textBlue">Discord</a>
              | 
            <a href = "mailto:utefa@g.skule.ca" target = "_blank" rel="noopener noreferrer" className = "textWhite">Email</a>
          </div>
          Copyright 2024 UTEFA
        </div>
      </div>
    );
  }
}
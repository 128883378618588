import React, {Component} from 'react';

export class JoinPane extends Component{
    render(){
        return(
            <div className="pane midPane">
                <div className="wow fadeInLeft title">Join Us</div>
                <div className="wow fadeInRight subSlogan">
                    Interested in joining utefa? 
                    Sign up or contact us to learn more
                </div>
            </div>
        )
    }
}
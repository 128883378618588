import React, {Component} from 'react';
import {NavBar} from '../NavBar'
import {Footer} from '../Footer';
import { JoinBar } from '../JoinBar';
import { PartnersPane } from './PartnersPane';
import { Partners } from './Partners';
import './partners.css';


export class PartnersPage extends Component {
  render(){
    return(
      <div>
          <NavBar
            isAuthenticated={this.props.isAuthenticated}
            userHasAuthenticated={this.props.userHasAuthenticated}
          />
          <PartnersPane />
          <Partners />
          <JoinBar />
          <Footer />
      </div>
    );
  }
}

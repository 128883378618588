import React, {Component} from 'react';

const imgDir = './images/pfps/'

const pfpDir = [
    [imgDir + "Sarah.jpg",
        imgDir + "Ella.jpg",
        imgDir + "Edward.jpg"],
    [imgDir + "Shawn.png",
        imgDir + "Harit.JPEG",
        imgDir + "Alex.jpg"],
    [imgDir + "Aditya.png",
        imgDir + "Joyce.jpg",
        imgDir + "Rosana.jpg"]
]

export class HomeTeam extends Component {
  render() {
    return(
        <div className = "homeTeam">
            <div className = "textTitle" >Meet The Team</div>
            <div id="carouselExample" class="carousel carousel-dark slide" data-bs-ride="carousel">
                <div class="wow fadeIn carousel-inner">
                    <div class="carousel-item active">
                        <div class = "carouselImages">
                            {pfpDir[0].map((pfp,i) => 
                                <img src = {pfp} alt = "profile" className = "homepfp borderNavy"></img>
                            )}
                        </div>
                        <div className = "carouselText">Management</div>
                    </div>
                    <div class="carousel-item">
                        <div class = "carouselImages">
                            {pfpDir[1].map((pfp, i) => 
                                <img src = {pfp} alt = "profile" className = "homepfp borderNavy"></img>
                            )}
                        </div>
                        <div className = "carouselText">Finance</div>
                    </div>
                    <div class="carousel-item">
                        <div class = "carouselImages">
                            {pfpDir[2].map((pfp, i) => 
                                <img src = {pfp} alt = "profile" className = "homepfp borderNavy"></img>
                            )}
                        </div>
                        <div className = "carouselText">Outreach</div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <a href = "/about" className = "wow pulse contentButton blueButton">Learn about our team</a>
        </div>
    );
  }
}

import React from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute'
import {Homepage} from './components/home/Homepage-v1';
import NotFound from './components/NotFound';
import {AlumniPage} from './components/alumni/AlumniPage';
import {AboutPage} from './components/about/AboutPage';
import {ResourcesPage} from './components/materials/MaterialsPage';
import {SchedulePage} from './components/schedule/SchedulePage';
import {JoinPage} from './components/join/JoinPage';
import { PartnersPage } from './components/partners/PartnersPage';

export default ({childProps, auth}) =>
  <Switch>
    <AppliedRoute path='/' exact component={Homepage} props={childProps} />
    <AppliedRoute path='/alumni' exact component={AlumniPage} props={childProps} />
    <AppliedRoute path='/materials' exact component={ResourcesPage} props={childProps} />
    <AppliedRoute path='/about' exact component={AboutPage} props={childProps} />
    <AppliedRoute path='/schedule' exact component={SchedulePage} props={childProps} />
    <AppliedRoute path='/partners' exact component={PartnersPage} props={childProps} />
    <AppliedRoute path='/join' exact component={JoinPage} props={childProps} />
    <Route component={NotFound} />
  </Switch>

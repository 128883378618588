import React, {Component} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export class NavBar extends Component {
  render () {
    return (
      <div>
        <Navbar expand="md" className="navbar navbar-dark" fixed="top">
          <Navbar.Brand href="/">
            <img src="./images/logos/LogoWhite.png" alt="UTEFA logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link href="/about" className = "textPink">About Us</Nav.Link>
              <Nav.Link href="/alumni" className = "textBlue">Alumni</Nav.Link>
              <Nav.Link href="/schedule" className = "textYellow">Schedule</Nav.Link>
              <Nav.Link href="/materials" className = "textPink">Resources</Nav.Link>
              <Nav.Link href="/partners" className = "textBlue">Partners</Nav.Link>
              <Nav.Link href="/join" className = "textYellow">Join Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
import React, {Component} from 'react';

export class MaterialsQA extends Component {
    render() {
      return(
        <div className = "questions">
            <div className = "questionsText">
                <div className = "textHeading">Slides missing or have questions?</div>
                <div className = "wow fadeInLeft normText">
                    Reach out on our Discord server and we'll resolve any issues at 
                    our earliest convenience. Note that some resources cannot be publicly posted - 
                    we will let you know if that is the case, so don't hesitate to ask us for anything.
                </div>
            </div>
            <img src = "./images/icons/resources.png" alt = "resources"></img>
        </div>
      );
    }
  }